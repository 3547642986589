import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Seo from '../components/Seo';
import FooterCredit from '../components/FooterCredit';
import BlogArchive from '../components/Blog/Archive';

type PageContextType = {
	slug: string;
	tag: string;
};

export default function Template(
	props: PageProps<GatsbyTypes.BlogAuthorPostsQuery, PageContextType>
) {
	const { data, pageContext } = props;

	return (
		<>
			<Seo
				description={`Category archive page for ${pageContext.tag} in WPEForm Blog.`}
				title={`${pageContext.tag} - Blog Archive | WPEForm WordPress Form Builder`}
			/>
			<BlogArchive
				data={data.allMdx}
				title={`${pageContext.tag} - Blog Archive`}
				subtitle={
					<>
						{data.allMdx.nodes.length} article
						{data.allMdx.nodes.length === 1 ? '' : 's'} published
					</>
				}
			/>
			<FooterCredit />
		</>
	);
}

export const query = graphql`
	query BlogTagPosts($tag: String!) {
		allMdx(
			filter: {
				fileAbsolutePath: { glob: "**/site-data/blog/**/*.mdx" }
				frontmatter: { published: { eq: true }, tags: { in: [$tag] } }
			}
			sort: { fields: frontmatter___date, order: DESC }
		) {
			nodes {
				id
				slug
				frontmatter {
					date
					heading
					featuredImage {
						childImageSharp {
							gatsbyImageData(
								layout: CONSTRAINED
								width: 850
								placeholder: TRACED_SVG
								aspectRatio: 2.0
							)
						}
					}
				}
				excerpt(pruneLength: 300, truncate: false)
			}
		}
	}
`;
